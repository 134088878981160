import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import PreviewCompatibleImage from '../PreviewCompatibleImage';
import AccentLine from '../AccentLine';

const StyledPost = styled.div`
	background-color: ${(props) => props.theme.colors.lightest};
	box-shadow: 0px 7px 23px -17px ${(props) => props.theme.colors.textGrey};
	height: 100%;
	position: relative;

	@media (max-width: 768px) {
		margin-bottom: 3rem;
	}

	.image-outer {
		position: relative;

		> h3 {
			position: absolute;
			top: 50%;
			right: 0;
			left: 0;
			transform: translateY(-50%);
			background-color: ${(props) => props.theme.colors.lightest};
			color: ${(props) => props.theme.colors.primary};
			font-size: 3rem;
			font-weight: ${(props) => props.theme.fonts.weights.bold};
			text-transform: uppercase;
			padding: 1rem 0.75rem;
			line-height: 1;
			z-index: 1;

			@media (max-width: 768px) {
				font-size: 2.5rem;
			}
		}
	}

	.main-details {
		> .column {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 3rem 2.5rem 5rem;

			.author {
				font-weight: ${(props) => props.theme.fonts.weights.bold};
				color: ${(props) => props.theme.colors.primary};
				font-size: 1.6rem;
				text-transform: uppercase;
			}

			[class*='AccentLine'] {
				margin-top: 1.5rem;
				margin-bottom: 1.5rem;
			}

			h3 {
				font-family: ${(props) => props.theme.fonts.secondary};
				font-weight: ${(props) => props.theme.fonts.weights.regular};
				font-size: 2.1rem;
				margin-bottom: 2rem;
			}

			.excerpt {
				font-size: 1.6rem;
			}
		}
	}

	.button {
		position: absolute;
		left: 50%;
		bottom: -2rem;
		transform: translateX(-50%);

		@media (max-width: 768px) {
			bottom: -1.5rem;
			font-size: 1.4rem;
		}
	}
`;

const PostDetail = ({ post, isFeatured }) => {
	return (
		<StyledPost>
			<div className="image-outer">
				{/* {!!isFeatured && (
					<h3 className="has-text-centered">
						Featured <br />
						Post
					</h3>
				)} */}
				<PreviewCompatibleImage imageInfo={post.frontmatter.featured_image} />
			</div>
			<div className="columns main-details">
				<div className="column">
					<div className="has-text-centered">
						<p className="author">By: {post.frontmatter.author}</p>
						<AccentLine align="center" />
						<h3>
							{post.frontmatter.title} <br />
						</h3>
					</div>
					<p className="excerpt">{post.excerpt}</p>
				</div>
			</div>
			<Link className="button button-3" to={post.fields.slug}>
				Read Full Post
			</Link>
		</StyledPost>
	);
};

export default PostDetail;
