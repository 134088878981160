import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import BlogPageRecent from '../components/blog/BlogPageRecent';
import BlogPagePast from '../components/blog/BlogPagePast';
import FilterBar from '../components/blog/FilterBar';

const StyledArticle = styled.article`
	padding-top: 15rem;
	padding-bottom: 7.5rem;

	@media (max-width: 768px) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.intro-section {
		margin-bottom: 2rem;
		padding: 0 1.5rem;

		@media (max-width: 768px) {
			margin-bottom: 2rem;
		}

		h2 {
			font-size: 3.5rem;
			color: ${(props) => props.theme.colors.tertiary};
			margin-top: 2rem;

			@media (max-width: 768px) {
				margin-top: 5rem;
			}
		}
	}

	.past-posts-section {
		padding-top: 10rem;
		padding-bottom: 10rem;

		h2 {
			font-size: 3.5rem;
			position: relative;
			overflow: hidden;
			margin-bottom: 3rem;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background-color: ${(props) => props.theme.colors.quaternary};
				position: absolute;
				top: 50%;
				margin-left: 3rem;
				transform: translateY(-50%);
			}
		}
	}
`;

export const BlogPageTemplate = ({ title, tags }) => {
	return (
		<StyledArticle>
			<section className="intro-section">
				<div className="container">
					<div className="columns is-centered">
						<div className="column has-text-centered">
							<h1>Ontario Real Estate News</h1>
							<AccentLine align="center" />
							<p className="sub-head">
								Catch up with monthly market updates and enjoy home owner tips
							</p>
							<FilterBar tags={tags} />
							<h2>Recent Posts</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="section recent-posts-section">
				<div className="container">
					<BlogPageRecent />
				</div>
			</section>
			<section className="section past-posts-section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<h2>Past Posts</h2>
						</div>
					</div>
					<BlogPagePast />
				</div>
			</section>
		</StyledArticle>
	);
};

BlogPageTemplate.propTypes = {
	title: PropTypes.string,
};

const BlogPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const tags = data.tags.edges.map(item => ({
		tag: item.node.frontmatter.tag,
		slug: item.node.fields.slug,
	}));
	return (
		<Layout>
			<SEO
				title={frontmatter.title}
				description="Stay Up To Date With Ontario Real Estate Statistics, Trends, Tips & Tricks Brought To You By Parker Coulter Realty."
			/>
			<BlogPageTemplate title={frontmatter.title} tags={tags} />
		</Layout>
	);
};

BlogPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default BlogPage;

export const blogPageQuery = graphql`
	query BlogPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
			}
		}
    tags: allMarkdownRemark(filter: {frontmatter: {template_key: {eq: "tag"}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            tag
          }
        }
      }
    }
	}
`;
