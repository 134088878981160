import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 40px 88px;
  margin-top: 60px;
  border-top: 1px solid #ddd;
  width: 100%;
  @media (max-width: 768px) {
		padding: 20px 0 20px 0;
    margin-top: 20px;
	}
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .blog-tag {
    display: inline-block;
    padding: 8px 12px;
    background-color: #A1A2A3;
    color: white;
    text-transform: uppercase;
    margin: 5px 10px;
    font-size: 14px;
    @media (min-width: 500px) {
      font-size: 16px;
    }
    &.active {
      background-color: #641823;
    }
  }
`;

const FilterBar = ({ tags, activeTag }) => {
  const sortedTags = tags.sort((a, b) => {
    if (a.tag > b.tag) {
      return 1;
    } else if (a.tag < b.tag) {
      return -1;
    }
    return 0;
  });
  return <Wrapper><h3>FILTERS</h3><div>{sortedTags.map((item) => <a href={item.slug} className={`blog-tag${activeTag === item.tag ? " active" : ""}`} key={item.slug}>{item.tag}</a>)}</div></Wrapper>;
}

export default FilterBar;