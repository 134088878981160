import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import PostDetail from './PostDetail';

const BlogPageRecent = () => {
	const data = useStaticQuery(graphql`
		query BlogPageRecentQuery {
			recentQuery: allMarkdownRemark(
				filter: {
					frontmatter: {
						template_key: { eq: "blog-post" }
						is_published: { eq: true }
					}
				}
				sort: { fields: frontmatter___date, order: DESC }
			) {
				edges {
					node {
						id
						excerpt(pruneLength: 75)
						fields {
							slug
						}
						frontmatter {
							title
							author
							featured_image {
								alt
								image
							}
						}
					}
				}
			}
			featuredQuery: markdownRemark(frontmatter: { name: { eq: "Settings" } }) {
				frontmatter {
					featuredPost
				}
			}
		}
	`);

	// A list of all blog posts
	const posts = data.recentQuery.edges;
	// The title of the featured post as set in the CMS
	const featured = data.featuredQuery.frontmatter.featuredPost;

	if (!!posts.length) {
		if (!!featured && !!featured !== '') {
			let count = 1;

			// Grab the one featured post, as set in CMS
			const featuredPost = posts.filter(
				({ node }) => node.frontmatter.title === featured
			)[0].node;

			return (
				<div className="columns">
					{/* Output featured post */}
					<div className="column is-4">
						<PostDetail post={featuredPost} isFeatured={true} />
					</div>
					{/* Output two more posts, not including the one marked as featured */}
					{posts.map(({ node }, i) => {
						if (node.frontmatter.title !== featured && count <= 2) {
							count++;
							return (
								<div
									className="column is-4"
									key={`${node.frontmatter.title}-${i}`}
								>
									<PostDetail post={node} />
								</div>
							);
						} else {
							return undefined;
						}
					})}
				</div>
			);
		} else {
			let count = 1;

			return (
				<div className="columns">
					{/* If there are now featured posts, out put three posts */}
					{posts.map(({ node }, i) => {
						if (count <= 3) {
							count++;
							return (
								<div
									className="column is-4"
									key={`${node.frontmatter.title}-${i}`}
								>
									<PostDetail post={node} />
								</div>
							);
						} else {
							return undefined;
						}
					})}
				</div>
			);
		}
	} else {
		return (
			<div className="columns is-centered">
				<div className="column has-text-centered">
					<h3>There are currently no available blog posts.</h3>
					<p>Please check back soon for updates!</p>
				</div>
			</div>
		);
	}
};

export default BlogPageRecent;
