import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import ShowMore from '@tedconf/react-show-more';

import PostDetail from './PostDetail';

const StyledPosts = styled.div`
	.post-column {
		@media (min-width: 769px) {
			margin-bottom: 10rem;
		}
	}

	.more-button {
		display: block;
		margin: 0 auto;
		background: none;
		border: none;
		text-transform: uppercase;
		position: relative;
		cursor: pointer;

		&[disabled] {
			display: none;
		}

		&::before {
			content: '';
			width: 1px;
			height: 1rem;
			background-color: ${(props) => props.theme.colors.primary};
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%);
		}

		&::after {
			content: '';
			width: 0.75rem;
			height: 0.75rem;
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%) rotate(45deg);
			border-right: 1px solid ${(props) => props.theme.colors.primary};
			border-bottom: 1px solid ${(props) => props.theme.colors.primary};
		}
	}
`;

const BlogPagePast = () => {
	const data = useStaticQuery(graphql`
		query BlogPagePastQuery {
			recentQuery: allMarkdownRemark(
				filter: {
					frontmatter: {
						template_key: { eq: "blog-post" }
						is_published: { eq: true }
					}
				}
				sort: { fields: frontmatter___date, order: DESC }
			) {
				edges {
					node {
						id
						excerpt(pruneLength: 75)
						fields {
							slug
						}
						frontmatter {
							title
							author
							featured_image {
								alt
								image
							}
						}
					}
				}
			}
			featuredQuery: markdownRemark(frontmatter: { name: { eq: "Settings" } }) {
				frontmatter {
					featuredPost
				}
			}
		}
	`);

	let posts = data.recentQuery.edges;
	const hasFeatured =
		data.featuredQuery.frontmatter.featuredPost &&
		data.featuredQuery.frontmatter.featuredPost !== ''
			? true
			: false;

	if (!!posts.length) {
		/*
		 * If there is a featured post set, remove the first two posts from the array,
		 *	so they will show in recent posts and not here, else remove the first three
		 *	posts from the array, as they will already be displayed in recent posts.
		 */

		if (!!hasFeatured) {
			posts.splice(0, 2);
		} else {
			posts.splice(0, 3);
		}

		return (
			<ShowMore items={posts} by={4}>
				{({ current, onMore }) => (
					<StyledPosts>
						<div className="columns is-multiline">
							{current.map(({ node }, i) => (
								<div
									className="column is-3 post-column"
									key={`${node.frontmatter.title}-${i}`}
								>
									<PostDetail post={node} />
								</div>
							))}
						</div>
						<button
							className="more-button"
							disabled={!onMore}
							onClick={() => {
								if (!!onMore) onMore();
							}}
						>
							Load More
						</button>
					</StyledPosts>
				)}
			</ShowMore>
		);
	} else {
		return (
			<div className="columns is-centered">
				<div className="column has-text-centered">
					<h3>There are currently no available blog posts.</h3>
					<p>Please check back soon for updates!</p>
				</div>
			</div>
		);
	}
};

export default BlogPagePast;
